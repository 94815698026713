<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" ></component>
      </div>
      <div slot="bg_map">
        <component :is="curMapComponent" ></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// store
import microSituationModule from '@/store/modules/microSituation'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    leftComponent: () => import('./components/Left'),
    centerComponent: () => import('./components/CellMap'),
    bottomComponent: () => import('./components/Bottom'),
    rightComponent: () => import('./components/Right.vue')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('microSituation')) {
      this.$store.registerModule('microSituation', microSituationModule)
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('microSituation')
  },
  methods: {
    init () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('leftComponent')
      this.setShowRightBlock(true)
      this.setRightComponent('rightComponent')
      this.setBottomComponent('bottomComponent')
      this.setBottomHeight('300px')
      this.setMapComponent('centerComponent')
    }
  }
}
</script>
