const getDefaultState = () => {
  return {
    startDate: '',
    endDate: '',
    userId: null,
    userArray: [] // 销售人员数据
  }
}

const state = getDefaultState()
const microSituation = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_start_date (state, data) {
      state.startDate = data
    },
    set_end_date (state, data) {
      state.endDate = data
    },
    set_user_id (state, data) {
      state.userId = data
    },
    set_user_array (state, data) {
      state.userArray = data
    }
  }
}

export default microSituation
